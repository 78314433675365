import React from 'react';
import ReactDOM from 'react-dom';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import FooterBanner from '../components/FooterBanner/FooterBanner';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ParallaxConfetti from "../components/ParallaxEffects/Confetti";
import {ParallaxProvider} from "react-scroll-parallax";
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import MainBanner from "../components/MainBanner/MainBanner";


export default function GuidesPage() {
  const dataRoot = window.data;
  const rows = [...Array( Math.ceil(dataRoot.guidesList.length / 3) )];
  const productRows = rows.map( (row, idx) => dataRoot.guidesList.slice(idx * 3, idx * 3 + 3) );
  const GuidesCards = productRows.map((products, idx) => {
    return (
      <Row className="m-auto" key={idx+10}>
        {products.map((product, index) => {
          return (
            <Card className="card-guides" key={index+100}>
              <Card.Body className="d-flex align-items-center">
                <Button href={product.path} variant="link">{product.text}</Button>
              </Card.Body>
            </Card>
          )
        })}
      </Row>
    );
  })

  return (
    <ParallaxProvider>
      <div className="bg-white">
        <Header></Header>
        <div className="header-common"><MainBanner></MainBanner></div>
        <div className="position-relative">
          <div className="parallax-wrap position-absolute">
            <ParallaxConfetti part></ParallaxConfetti>
          </div>
          <Container className="navbar-main-container pricing-page d-block">
            <Row>
              <Col lg={12} className="col-xxl-10 offset-xxl-1">
                <div className="d-flex flex-wrap">
                  {GuidesCards}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <FooterBanner></FooterBanner>
        <Footer></Footer>
      </div>
    </ParallaxProvider>
  );
}

if (document.getElementById('page-guides')) {
  ReactDOM.render(<GuidesPage />, document.getElementById('page-guides'));
}
