import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav'

function Footer() {
  const dataRoot = window.data;

  return (
    <footer className="text-white footer-common">
      <Container className="navbar-main-container d-block">
        <Row>
          <Col lg={12} className="col-xxl-10 offset-xxl-1">
          <Row>
            <Col xl={4} lg={4} md={4} className="align-self-end text-left">
              <p className="logo-slogan">
                <Image src={`${dataRoot.paths.asset}media/logos/law-on-earth-logo-small.png`} /> {dataRoot.environment.APP_MOTTO}
              </p>
              <ul className="list-unstyled mb-1 p-0">
                {dataRoot.usefulLinksLeftColumn.map((link, index) =>
                  <li className="footer-common-list" key={index}>
                    <Nav.Link as="a" target={link.target} className="footer-common-list-link" href={link.path} title={link.text}>{link.text}</Nav.Link>
                  </li>
                )}
              </ul>
              <p className="copyright mb-0">
                &copy; {new Date().getFullYear()} {dataRoot.environment.APP_COPY}<br />
                {dataRoot.copyRightLinks.map((link, index) =>
                  <React.Fragment key={index}>
                    <Nav.Link as="a" bsPrefix="text-gray" href={link.path} title={link.text}>{link.text}</Nav.Link>
                    {dataRoot.copyRightLinks[index + 1] && ' | '}
                  </React.Fragment>
                )}
              </p>
            </Col>
            <Col xl={5} lg={4} md={4} className="align-self-end text-left">
              <Row><i className="footer-common-lines"/>
                <Col xl={{ span: 8, offset: 2 }} lg={{ span: 12, offset: 0 }}>
                  <h5>Useful Links</h5>
                  <ul className="list-unstyled m-0 p-0">
                    {dataRoot.usefulLinks.map((link, index) =>
                      <li className="footer-common-list" key={index}>
                        <Nav.Link as="a" target={link.target} className="footer-common-list-link" href={link.path} title={link.text}>{link.text}</Nav.Link>
                      </li>
                    )}
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col xl={3} lg={4} md={4} className="align-self-end footer-address">
              <h5>Contact</h5>
              <address className="footer-common-contacts">
                {dataRoot.environment.APP_PHONE}<br/>
                <Nav.Link as="a"
                          bsPrefix="h5 font-weight-light text-white"
                          href={`mailto: ${dataRoot.environment.MAIL_REPLY_TO_ADDRESS}`}
                          title={`Mail to ${dataRoot.environment.APP_NAME}`} > {dataRoot.environment.MAIL_REPLY_TO_ADDRESS}</Nav.Link><br/>
              </address>
              <address>
                {dataRoot.environment.ADDRESS1},<br/>
                {dataRoot.environment.ADDRESS2}
              </address>
            </Col>
          </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer;
