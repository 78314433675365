import React from "react";
import Image from "react-bootstrap/Image";
import {Parallax} from 'react-scroll-parallax';
import {useScreenClass} from "react-grid-system";

const ParallaxConfetti = (props) => {
  const screenClass = useScreenClass();
  const disabledAttribute = ['lg', 'xl', 'xxl'].includes(screenClass) ? false : true;
  return (
    <>
      <Parallax x={[50, -50]} y={[15, -15]} className="position-absolute diamond-left1" disabled={disabledAttribute}>
        <Image src='media/various/white_confetti_shapes/diamond_129129.svg' />
      </Parallax>
      <Parallax x={[20, -20]} y={[15, -15]} className="position-absolute diamond-left2" disabled={disabledAttribute}>
        <Image src='media/various/white_confetti_shapes/diamondlong_180159.svg' />
      </Parallax>
      <Parallax x={[50, -50]} className="position-absolute triangle-middle-left" disabled={disabledAttribute}>
        <Image src='media/various/white_confetti_shapes/triangle_135134.svg' />
      </Parallax>
      <Parallax x={[50, -50]} y={[15, -15]} className="position-absolute diamond-right1" disabled={disabledAttribute}>
        <Image src='media/various/white_confetti_shapes/diamond_129129.svg' />
      </Parallax>
      <Parallax x={[50, -50]} y={[15, -15]} className="position-absolute diamond-right2" disabled={disabledAttribute}>
        <Image src='media/various/white_confetti_shapes/diamondlong_180159.svg' />
      </Parallax>
      <Parallax x={[50, -50]} y={[15, -15]} className="position-absolute diamond-right3" disabled={disabledAttribute}>
        <Image src='media/various/white_confetti_shapes/diamondlong_180159.svg'/>
      </Parallax>

      {(!props.part) &&
      <>
        <Parallax x={[50, -50]} className="position-absolute diamond-bottom1" disabled={disabledAttribute}>
          <Image src='media/various/white_confetti_shapes/diamondlong_180159.svg'/>
        </Parallax>
        <Parallax x={[50, -50]} y={[15, -15]} className="position-absolute diamond-bottom2" disabled={disabledAttribute}>
          <Image src='media/various/white_confetti_shapes/diamond_129129.svg'/>
        </Parallax>
        <Parallax x={[50, -50]} y={[15, -15]} className="position-absolute diamond-bottom3-out" disabled={disabledAttribute}>
          <Image src='media/various/white_confetti_shapes/diamondlong_180159.svg'/>
        </Parallax>
        <Parallax x={[50, -50]} y={[15, -15]} className="position-absolute diamond-bottom4" disabled={disabledAttribute}>
          <Image src='media/various/white_confetti_shapes/diamond_129129.svg'/>
        </Parallax>
        <Parallax x={[50, -50]} className="position-absolute triangle-bottom1" disabled={disabledAttribute}>
          <Image src='media/various/white_confetti_shapes/triangle_135134.svg'/>
        </Parallax>
        <Parallax x={[50, -50]} className="position-absolute diamond-bottom5" disabled={disabledAttribute}>
          <Image src='media/various/white_confetti_shapes/diamondlong_180159.svg'/>
        </Parallax>
        <Parallax x={[50, -50]} className="position-absolute diamond-bottom6" disabled={disabledAttribute}>
          <Image src='media/various/white_confetti_shapes/diamondlong_180159.svg'/>
        </Parallax>
      </>
      }
    </>
  )
}

export default ParallaxConfetti;
