import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BlueCard from "../BlueCard/BlueCard";
import BlueParallaxConfetti from "../ParallaxEffects/BlueConfetti";


function GettingStarted(props) {
  const dataRoot = window.data;
  const { activeTab } = props;
  const servicesData = activeTab==='Users' || activeTab === 'Wellness at work' ? dataRoot.servicesUser : dataRoot.servicesAdvisor;
  const servicesDataContent = activeTab==='Users' ? dataRoot.servicesUserContent : dataRoot.servicesAdvisorContent;

  return (
    <div className="main-page-blue-section main-page-parallax-blue-banner position-relative">
      <div className="parallax-wrap position-absolute">
        <BlueParallaxConfetti ></BlueParallaxConfetti>
      </div>
      <Container className="py-5">
        <Row>
          <Col lg={12} className="col-xxl-10 offset-xxl-1">
            <h2>{dataRoot.servicesHeader}</h2>
            <Row className="card-space">
              {servicesData.map((service, index) =>
                <BlueCard service={service} index={index} key={service.id}></BlueCard>
              )}
            </Row>
            <div className="main-page-blue-section-summary text-center">
              <b>{dataRoot.servicesTitle}</b>
              <br />{servicesDataContent}</div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default GettingStarted;
