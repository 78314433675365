import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import Header from '../components/Header/Header';
import TabsMainPage from '../components/TabsMainPage/TabsMainPage';
import Footer from '../components/Footer/Footer';
import FooterBanner from '../components/FooterBanner/FooterBanner';
import ArticleSection from '../components/ArticleSection/ArticleSection';
import GettingStarted from "../components/GettingStarted/GettingStarted";
import MainBanner from "../components/MainBanner/MainBanner";
import {ParallaxProvider} from "react-scroll-parallax";

function IndexPage() {
  const dataRoot = window.data;
  const [activeTab, setActiveTab] = useState(dataRoot.descriptionUse[0].name);

  const changeTabsHandler = (key) => {
    setActiveTab(key)
  }
  return (
    <ParallaxProvider>
      <div className="bg-white">
        <Header></Header>
        <div className="header-common"><MainBanner></MainBanner></div>
        <TabsMainPage activeTab={activeTab} onChangeTabs={changeTabsHandler}></TabsMainPage>
        <GettingStarted activeTab={activeTab}></GettingStarted>
        <ArticleSection></ArticleSection>
        <FooterBanner></FooterBanner>
        <Footer></Footer>
      </div>
    </ParallaxProvider>
  );
}

export default IndexPage;

if (document.getElementById('page-index')) {
  ReactDOM.render(<IndexPage />, document.getElementById('page-index'));
}
