/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

require('./pages/main');
require('./pages/pricing');
require('./pages/guides');
require('./pages/about');
