import React from "react";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import {Parallax} from 'react-scroll-parallax';
import { useScreenClass } from 'react-grid-system';

const BlueParallaxConfetti = () => {
  const screenClass = useScreenClass();
  const disabledAttribute = ['lg', 'xl', 'xxl'].includes(screenClass) ? false : true;
  return (
    <>
      <Parallax x={[5, -5]} y={[5, -5]} className="position-absolute blue-triangle-left" disabled={disabledAttribute}>
        <Image src='media/various/blue_confetti_shapes/blue_floating_triangle933_1095.svg' />
      </Parallax>
      <Parallax x={[20, -20]} y={[15, -15]} className="position-absolute blue-diamond-left1" disabled={disabledAttribute}>
        <Image src='media/various/blue_confetti_shapes/blue_floating_shape171_170.svg' />
      </Parallax>
      <Parallax x={[20, -20]} y={[15, -15]} className="position-absolute blue-diamond-left2" disabled={disabledAttribute}>
        <Image src='media/various/blue_confetti_shapes/blue_floating_shape_308_310.svg' />
      </Parallax>
      <Parallax x={[20, -20]} y={[15, -15]} className="position-absolute blue-diamond-left3" disabled={disabledAttribute}>
        <Image src='media/various/blue_confetti_shapes/blue_floating_shape75_75.svg' />
      </Parallax>
    </>
  )
}

export default BlueParallaxConfetti;
