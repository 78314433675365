import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import FooterBanner from '../components/FooterBanner/FooterBanner';
import MainBanner from "../components/MainBanner/MainBanner";
import {ParallaxProvider} from "react-scroll-parallax";
import ParallaxConfetti from "../components/ParallaxEffects/Confetti";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

function AboutPage() {
  const dataRoot = window.data;

  return (
    <ParallaxProvider>
      <div className="bg-white">
        <Header></Header>
        <div className="header-common"><MainBanner></MainBanner></div>
        <div className="position-relative">
          <div className="parallax-wrap position-absolute">
            <ParallaxConfetti part></ParallaxConfetti>
          </div>
          <Container className="navbar-main-container pt-6 pb-6 d-block">
            <Row>
              <Col lg={12} className="col-xxl-10 offset-xxl-1">
                <div className="d-flex flex-wrap">
                  <Card className="bg-white card-about">
                    <Card.Body>
                      {dataRoot.loeStories.map((story, index) =>
                        <p className="mb-5" key={index}>{story.text}</p>
                      )}
                      <Image src={`${dataRoot.paths.asset}assets/img/lord-mayors-award-2019-2.png`} alt="Lord Mayor's Award 2019" className="mw-100"/>
                    </Card.Body>
                  </Card>
                </div>
                {/*<Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                  <Tab eventKey="home" title="Our story">
                    <div className="pt-5 pb-5">
                      <p>Research indicates that even in 2019, the majority of the world’s population cannot afford or access legal assistance. As a result, people are making decisions involving their legal rights, or making no decisions at all, and this is impacting not only their current life situation but their future also.
                      </p>
                      <p>To better understand and manage their legal situations, many Australians are looking for answers on Google, but without any idea whether the information they’ve found actually addresses their situation and is relevant to the State of Australia and region their problem is based in, noting many of the laws change from State to State.</p>
                      <p>By creating the Learning Centre which is geo-tagged, the system can identify where the customer is located and will only provide information that is relevant to that location. By providing both videos and articles, those who have difficulty with reading or are vision-impaired can still access legal information in the videos, and those who are hearing impaired have the articles and infographics.</p>
                    </div>
                  </Tab>
                  <Tab eventKey="profile" title="Why Law on Earth">
                    <div className="pt-5 pb-5">
                      <h2 className="mb-5">Why Law on Earth</h2>
                      <p>Business law, family law, property law, trademarks, patents, wills and estates - isn’t always easy knowing whether you’re accessing the right information. Access hundreds of free, geo-tagged articles to help you better understand the law.</p>
                    </div>
                  </Tab>
                  <Tab eventKey="values" title="Our values">
                    <div className="pt-5 pb-5">
                      <h2 className="mb-5">Our values</h2>
                      <p>Business law, family law, property law, trademarks, patents, wills and estates - isn’t always easy knowing whether you’re accessing the right information. Access hundreds of free, geo-tagged articles to help you better understand the law.</p>
                    </div>
                  </Tab>
                  <Tab eventKey="contact" title="Contact us">
                    <div className="pt-5 pb-5">
                      <p>By creating the Learning Centre which is geo-tagged, the system can identify where the customer is located and will only provide information that is relevant to that location. By providing both videos and articles, those who have difficulty with reading or are vision-impaired can still access legal information in the videos, and those who are hearing impaired have the articles and infographics.</p>
                    </div>
                  </Tab>
                </Tabs>*/}
              </Col>
            </Row>
          </Container>
        </div>
        <FooterBanner></FooterBanner>
        <Footer></Footer>
      </div>
    </ParallaxProvider>
  );
}

export default AboutPage;

if (document.getElementById('page-about')) {
  ReactDOM.render(<AboutPage />, document.getElementById('page-about'));
}
