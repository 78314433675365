import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

function FooterBanner() {
  const dataRoot = window.data;
  return (
    <Container className="text-white">
      <Row className="reach-out">
        <Col lg={12} className="col-xxl-10 offset-xxl-1">
          <Row>
            <Col lg="8">
              <p>{dataRoot.reachOutContent}</p>
            </Col>
            <Col lg="4">
              <div className="d-flex justify-content-end align-items-center h-100 w-100 text-right">
                <p className="m-0 line-height-normal w-100">
                  <Button className="button-yellow-big on-intercom-run" href={dataRoot.links.bookDemo} variant="link">Book a demo</Button>
                </p>
                {/*<p className="m-0 line-height-normal">
                  <Button className="button-link-blue-transparent" href="#" variant="primary">Learn more</Button>
                </p>*/}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default FooterBanner;
