import React, { useContext } from 'react';
import Accordion from 'react-bootstrap/Accordion'
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AccordionContext from 'react-bootstrap/AccordionContext';
import {useAccordionToggle} from 'react-bootstrap/AccordionToggle';


function CustomToggle({eventKey, children}) {
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;
  const decoratedOnClick = useAccordionToggle( eventKey );

  return (
    <Accordion.Toggle as={Card.Header} className={isCurrentEventKey ? 'open': 'shut'} onClick={decoratedOnClick}>
      {children}
    </Accordion.Toggle>
  );
}

function PricingAccordion() {
  const dataRoot = window.data;
  return (
    <Row className="pricing-page-accordion">
      <Col>
        <h2 className="latest-article-heading mb-4">{dataRoot.pricingHeader}</h2>
        <Accordion>
          {dataRoot.pricingDocuments.map((document, index) =>
            <Card key={document.id}>
              <CustomToggle eventKey={document.id}> {document.header}</CustomToggle>
              <Accordion.Collapse eventKey={document.id}>
                <Card.Body>
                  <div dangerouslySetInnerHTML={{ __html: document.content }} />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )}
        </Accordion>
      </Col>
    </Row>
  )
}

export default PricingAccordion;
