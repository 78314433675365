import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import {ScreenClassRender} from "react-grid-system";

function MainNavigation() {

  const dataRoot = window.data;
  const [menuOpen, setMenuOpen] = useState(false);

  // Auxiliary functions
  const isScreenBig = (screenClass) => ['lg', 'xl', 'xxl'].includes(screenClass);
  const auth = dataRoot.user.authenticated;
  return (
    <ScreenClassRender render={screenClass => (
      <section className="navbar-main-wrap">
        <Navbar expand="lg" className={`navbar-main ${menuOpen && !isScreenBig(screenClass)  ? 'show' : ''}`}  fixed="top">
          <Container className="navbar-main-container d-block">
            <Row>
              <Col lg={12} className="col-xxl-10 offset-xxl-1 d-flex justify-content-between flex-wrap">
                <Navbar.Brand href="/">
                  <Image src={`${dataRoot.paths.asset}media/logos/law-on-earth.png`}
                         alt="Making legal services accessible to everyone, anywhere, anytime" />
                </Navbar.Brand>
                <div className="navbar-toggler" onClick={() => setMenuOpen(!menuOpen)}>
                  <span className="navbar-burger"><i className="navbar-burger-lines" /><i className="bi bi-x navbar-close"></i></span>
                </div>
                <Navbar.Collapse id="basic-navbar-nav" className={menuOpen ? 'show' : null}>
                  <Nav className="mr-auto ml-auto">
                    {dataRoot.topMenuLinks.map((link, index) =>
                      <Nav.Link href={link.path}  key={index} className="navbar-main-link" active={link.path === dataRoot.paths.route}>{link.text}</Nav.Link>
                    )}
                  </Nav>
                  <Nav>
                    {isScreenBig(screenClass) ? (
                      <>
                        <Nav.Link href={dataRoot.topMenuLinkAuth.path} className="navbar-main-link">{dataRoot.topMenuLinkAuth.text}</Nav.Link>
                        <DropdownButton
                           title={dataRoot.topMenuDropdownText}
                           className="header-dropdown-btn"
                        >
                           {dataRoot.topMenuDropdownLinks.map((link, index) =>
                             <Dropdown.Item href={link.path} key={index}>{link.text}</Dropdown.Item>
                           )}
                        </DropdownButton>
                      </>
                    ) : (
                      <div className="navbar-main-mobile">
                        {/*<p className="navbar-main-header">{dataRoot.topMenuLinkAuth.text}</p>*/}
                        <Nav.Link href={dataRoot.topMenuLinkAuth.path} className="navbar-main-header navbar-main-mobile-link">{dataRoot.topMenuLinkAuth.text}</Nav.Link>
                        {dataRoot.topMenuDropdownLinks.map((link, index) =>
                          <Nav.Link href={link.path} className="navbar-main-mobile-link" key={index}>
                            {!auth && 'Register '}
                            <span className={`${ auth ? 'text-capitalize' :'text-capitalize'}`}>{link.text}</span></Nav.Link>
                        )}
                      </div>
                    )}
                  </Nav>
                </Navbar.Collapse>
              </Col>
            </Row>
          </Container>
        </Navbar>
      </section>
    )} />
  );
}

export default MainNavigation;

