import React from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";

function BlueCard(props) {
  return (
    <Col lg={3} sm={6} xs={12} className="col-xxl-3">
      <div className="blur-card-blue">
        <Card>
          <Card.Body>
            <i className={`bi bi-${props.service.icon} blur-card-blue-icon`}></i>
            <Card.Title>{props.service.title} <span>{props.service.titleHidden}</span></Card.Title>
            <Card.Text>{props.service.description}</Card.Text>
          </Card.Body>
        </Card>
      </div>
    </Col>
  )
}

export default BlueCard;
