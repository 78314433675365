import React, {useState, useEffect} from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

const PopoverHelper = (props) => {

  const [hoverMove, setHoverMove] = useState(false);
  const [popoverIcon, setPopoverIcon] = useState(false);
  const renderTooltip = (props) => <Tooltip id="tooltip-top" className="popover-dark">{props.popoverContent}</Tooltip>
  const toggleHover = () => {
    setHoverMove(!hoverMove);
  }

  useEffect(() => {
    return hoverMove ? setPopoverIcon('bi-info-circle-fill'):setPopoverIcon('bi-info-circle')
  }, [hoverMove]);
  return (
    <OverlayTrigger
      key="top"
      placement="top-start"
      overlay={renderTooltip(props)}
    >
      <div><i className={`bi popover-circle ${popoverIcon}`}
              onMouseEnter={toggleHover}
              onMouseLeave={toggleHover}
      ></i></div>
    </OverlayTrigger>
  )
}

export default PopoverHelper;
