import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { Visible } from 'react-grid-system';
import ParallaxConfetti from "../ParallaxEffects/Confetti";


function TabsMainPage(props) {
  const dataRoot = window.data;

  // Props
  const { activeTab, onChangeTabs } = props;

  const TabsView = dataRoot.descriptionUse.map((tab, index) =>
    <Tab eventKey={tab.name} title={tab.name} key={tab.name} className="main-page-tab-view">
      <h2>{tab.heading}</h2>
      <Row className="col-lg-8">{tab.description}</Row>
      {tab.sections.map(function (feature, index) {
        return (
          <Row className="main-page-tab-row" key={index}>
            <Col xs={{order:2}} lg={{order: index % 2 ? index + 1 : index, span: index % 2 ? 8:8 }} className={`${index % 2 ? "text-right" : "text-left"}`}>
              <div className="w-100 h-100">
                {
                  feature.image ?
                    <div>
                      <Image src={feature.image} />
                      <Visible xs sm md>
                        <Col md={{ span: 10, offset: 1 }} className="text-left">
                          {feature.description}
                          <p className="text-center mt-4">
                            <Button variant="link"  href={feature.link} className="button-link-blue-transparent">Try it out!</Button>
                          </p>
                        </Col>
                      </Visible>
                    </div>
                    :
                    <div className="main-page-tab-buttons text-center flex-column">
                      <p><Button className="button-white on-intercom-run" href={dataRoot.links.bookDemo} variant="link" >Book a demo</Button></p>
                      <p><Button className="button-yellow-big" href="/register-as-advisor" variant="primary">Join Free</Button></p>
                    </div>
                }
              </div>
            </Col>
            <Col xs={{order:1, span: 12}} lg={{order: index % 2 ? index : index + 1, span: index % 2 ? 4:4 }} className="align-self-center">
              <h4 className="main-page-tab-blue-head">{feature.title}</h4>
              <h3 className="main-page-tab-heading">{feature.heading}</h3>
              <Visible lg xl xxl>
                <p>{feature.description}</p>
                {activeTab === 'Users' &&
                <Button href={feature.link} variant="link" className="button-link-blue-transparent">
                  Try it out!
                </Button>}
              </Visible>
            </Col>
          </Row>
        );
      })}
    </Tab>
  );
  const changeActiveTab = (key) => {
    onChangeTabs && onChangeTabs(key);
  }
  return (
    <div className="position-relative">
      <div className="parallax-wrap position-absolute">
        <ParallaxConfetti></ParallaxConfetti>
      </div>
      <Container>
        <Row>
          <Col lg={12} className="col-xxl-10 offset-xxl-1">
            <div className="main-page-tab">
              <Tabs defaultActiveKey={activeTab} onSelect={changeActiveTab} id="tab-user-advisor" variant="pills">{TabsView}</Tabs>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    )
}

export default TabsMainPage;
