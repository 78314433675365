import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import PopoverHelper from "../Popover/Popover";
import {Visible} from "react-grid-system";
import Api from "../../Api";
import Spinner from "react-bootstrap/Spinner";
import parse from "react-html-parser";
import { Modal } from "react-bootstrap";

function PricingAccordion() {
  const dataRoot = window.data;

  // State
  const [isSubscribed, setIsSubscribed] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const lineStyle = {lineHeight: '1.3rem'}

  // Effects
  useEffect(() => {
    api();
  }, []);

  async function api()
  {
    let response;
    try {
      response = await Api.subscriptions();
      setLoading(false);
    } catch (err) {
      // TODO;
      return null;
    }

    setIsSubscribed(response.isSubscribed);
    setSubscriptions(parseSubscriptions(response.subscriptions));

  }

  function parseSubscriptions(subscriptions) {
    return Object.keys(subscriptions).map((subscriptionCode) => ({
      id: subscriptionCode,
      subId: subscriptions[subscriptionCode].subId,
      frequency: subscriptions[subscriptionCode].frequency,
      name: subscriptions[subscriptionCode].name,
      price: Math.ceil(subscriptions[subscriptionCode].price / 100),
      price_label: subscriptions[subscriptionCode].price_label,
      accessCatalogue: subscriptions[subscriptionCode].free_docs === -1 ? 'Unlimited' : 'Pay per Document',
      accessDownloadMWFormat: true,
      accessDiscountedVideo: Number(dataRoot.pricingPage.price.videoDefault) > Number(subscriptions[subscriptionCode].price_video),
      accessVideo: true,
      accessArticles: true,
      accessGeo: true,
      subscribed: subscriptions[subscriptionCode].subscribed,
      recommended: subscriptions[subscriptionCode].recommended,
    }))
  }

  let TrueFalse = (subscriptionOption) => subscriptionOption ? <i className="bi bi-check2 pricing-check"></i> : <i className="bi bi-x pricing-close"></i>;

  const optionRow = (title) => (
    <Container key={title}>
      <Row>
        <Col lg={subscriptions.length === 1 ? 4 : 3} className="pt-3 pb-3">{dataRoot.pricingHeaders[title]}</Col>
        <Col lg={subscriptions.length === 1 ? 2 : 1} className="align-self-center" align="center">
          <PopoverHelper popoverContent={dataRoot.pricingPopover[title]}></PopoverHelper>
        </Col>
        {subscriptions.map((account, index) => (
            <Col lg={2} align="center" key={account.id} className="align-self-center pt-1 pb-1">
              {title === 'accessCatalogue' ?
                <div className={`${account.id === 'free' ? "column-header-red" : "unlimited"}`}>
                  {account.accessCatalogue}
                </div>
                :
                <>{TrueFalse(account[title])}</>}
            </Col>
          )
        )}
      </Row>
    </Container>
  );

  function modalOpen(subId) {
    setSelectedSubscription(subId);
  }
  function modalClose() {
    setSelectedSubscription(null);
  }
  function modalSubmit() {
    // TODO: this is backwards compatibility

    const form = document.createElement('form');
    form.method = 'POST';
    form.action = dataRoot.pricingPage.links.processSubscription;

    const params = {
      plan: selectedSubscription,
      _token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    };
    for (const key in params) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = params[key];
        form.appendChild(hiddenField);
    }

    document.body.appendChild(form);
    form.submit();
  }

  function isHighlighted(account) {
    return (isSubscribed && account.subscribed) || (!isSubscribed && account.recommended);
  }

  const auth = dataRoot.user.authenticated;
  function subscribeName(account) {
    if (!auth) {
      return 'Get Started';
    }
    return account.subscribed ? 'Current Plan' : 'Switch';
  }
  function monthlyPrice(account) {
    let price = account.price
    switch (account.frequency) {
      case 'quarterly':
        price /= 3;
        break;
      case 'half-yearly':
        price /= 6;
        break;
    }

    return '$'+parseFloat(price).toFixed(2)+'/<span style="font-size: 0.9rem" class="small">month<sup>*</sup></span>'
  }
  function frequencyLabel(account) {
    let label = '<sup>*</sup>'
    switch (account.frequency) {
      case 'monthly':
        label += 'Billed Monthly'
        break;
        case 'quarterly':
          label += `Billed Every 3 Months $${account.price}`
        break;
        case 'half-yearly':
          label += `Billed Every 6 Months $${account.price}`
        break;
      default:
        label = ''
    }

    return label
  }

  return (
    <Row className="pricing-subscription">
      <Col>
        {isLoading ?
          <Col lg={12} className="align-items-top justify-content-center d-flex w-100 min-vh-100">
            <Spinner animation="border" className="article-spinner"/>
          </Col>
          :
          <>
            <Visible xs sm md>
              <>
                <div className="mb-5 mt-5" align="center">
                  <Image src="media/various/globe.png" className="m-auto"/>
                </div>
                {subscriptions.map((account, index) => (
                    <div key={account.id} className="pricing-subscription-mobile mb-5">
                      <div className="row mt-3 mb-3">
                        <Col>
                          <div className={isHighlighted(account) ? "recommended-color" : null}>
                            {account.name}<span
                            className={`${account.price === 0 ? "free-color" : "recommended-color"} pl-3`}>${account.price_label || account.price}</span>
                          </div>
                        </Col>
                        <Col align="right">
                          <Button
                            className={isHighlighted(account) ? 'btn-blue ' : 'btn-white'}
                            onClick={() => {modalOpen(account.subId)}} disabled={auth && account.subscribed}
                            href={!auth && account.id === 'free' ? dataRoot.topMenuLinkAuth.path : null}
                            variant={!auth ? 'link' : 'primary'}>
                            {subscribeName(account)}
                          </Button>
                        </Col>
                      </div>
                      <div className="d-flex flex-column pricing-table">
                        <div>
                          <div className="row align-items-center">
                            <Col xs={6}>{dataRoot.pricingHeaders.accessCatalogue}</Col>
                            <Col xs={2} className="text-center">
                              <PopoverHelper popoverContent={dataRoot.pricingPopover.accessCatalogue}></PopoverHelper>
                            </Col>
                            <Col xs={4}
                                 className={`${account.id === 'free' ? "column-header-red" : "unlimited"} text-center`}>{account.accessCatalogue}</Col>
                          </div>
                        </div>
                        <>
                          {['accessDownloadMWFormat', 'accessDiscountedVideo', 'accessVideo', 'accessArticles', 'accessGeo'].map((column) => (
                            <div key={column}>
                              <div className="row align-items-center">
                                <Col xs={6}>{dataRoot.pricingHeaders[column]}</Col>
                                <Col xs={2} className="text-center">
                                  <PopoverHelper popoverContent={dataRoot.pricingPopover[column]}></PopoverHelper>
                                </Col>
                                <Col xs={4} className="text-center">{TrueFalse(account[column])}</Col>
                              </div>
                            </div>
                          ))}
                        </>
                      </div>
                    </div>
                  )
                )}
              </>
            </Visible>
            <Visible lg xl xxl>
              <Row className="pricing-subscription-desktop mt-5 mb-5">
                <Col lg={subscriptions.length === 1 ? 6 : 4} valign="center" className="align-self-center">
                  <Image src="media/various/globe.png" className="m-auto"/>
                </Col>
                {subscriptions.map((account, index) => (
                    <Col lg={2} key={account.id} align="center"
                         className={`${isHighlighted(account) ? 'blue ' : ''}bit-price`}>
                      <p
                        className={`${isHighlighted(account) ? 'visible ' : 'invisible'} most-popular`}>{isSubscribed ? 'Congratulations' : 'Best value'}</p>
                      <p className="bit-price-name">{account.name}</p>
                      <p
                        className={`${dataRoot.pricingPage.company.hasOrganisationSubscription && !dataRoot.pricingPage.company.userPays ? 'd-none' : ''} ${account.name === "Starter" ? 'grey-color ' : ''}bit-price-dollars`}>{account.price_label || parse(monthlyPrice(account))}</p>
                      <Button
                        className={isHighlighted(account) ? 'btn-blue ' : 'btn-white'}
                        onClick={() => {modalOpen(account.subId)}} disabled={auth && account.subscribed}
                        href={!auth && account.id === 'free' ? dataRoot.topMenuLinkAuth.path : null}
                        variant={!auth ? 'link' : 'primary'}>
                        {subscribeName(account)}
                      </Button>

                        <p   style={lineStyle} className={`${dataRoot.pricingPage.company.hasOrganisationSubscription && !dataRoot.pricingPage.company.userPays ? 'd-none' : ''} p-2 mt-2`}>{parse(frequencyLabel(account))}</p>
                    </Col>
                  )
                )}
              </Row>
              <div className="pricing-subscription-desktop">
                <p className="section-header">{dataRoot.pricingSections[0]}</p>
                <div
                  className="pricing-table mb-7">{['accessCatalogue', 'accessDownloadMWFormat'].map((rowTitle) => optionRow(rowTitle))}</div>
                <p className="section-header">{dataRoot.pricingSections[1]}</p>
                <div
                  className="pricing-table mb-7">{['accessDiscountedVideo', 'accessVideo'].map((rowTitle) => optionRow(rowTitle))}</div>
                <p className="section-header">{dataRoot.pricingSections[2]}</p>
                <div
                  className="pricing-table mb-7">{['accessArticles', 'accessGeo'].map((rowTitle) => optionRow(rowTitle))}</div>
              </div>
            </Visible>
          </>
        }
      </Col>
      <Modal show={!!selectedSubscription} onHide={modalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Attention</Modal.Title>
        </Modal.Header>
        <Modal.Body>{dataRoot.pricingPage.user.hasDefaultPaymentMethod ? 'Your credit/debit card will be charged. Do you want to proceed?' : 'You do not have a valid credit/debit card on file. You will be redirected to your account area to one now.'}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={modalClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={modalSubmit}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  )
}

export default PricingAccordion;
