import React from "react";
import MainNavigation from '../MainNavigation/MainNavigation';

function Header() {

  return (
    <header>
      <MainNavigation></MainNavigation>
    </header>
  )
}

export default Header;
