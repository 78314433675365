import qs from 'qs';

export default class Api {
  static articlesLatest() {
    return this.get('/api/articles/latest');
  }

  static subscriptions() {
    return this.get('/api/subscriptions');
  }

  static headers(headers = {}) {
    return {
      Accept: 'application/json',
      'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      ...headers,
    };
  }

  static get(url, params = {}) {
    const query = qs.stringify(params, { addQueryPrefix: true });
    const requestOptions = {
      credentials: 'same-origin',
      headers: this.headers(),
      method: 'GET',
    };
    return fetch(url + query, requestOptions).then((response) => this.handleResponse(response));
  }

  static post(url, body = {}) {
    const requestOptions = {
      body: JSON.stringify(body),
      credentials: 'same-origin',
      headers: this.headers({ 'Content-Type': 'application/json' }),
      method: 'POST',
    };
    return fetch(url, requestOptions).then((response) => this.handleResponse(response));
  }

  static delete(url, params = {}) {
    const query = qs.stringify(params, { addQueryPrefix: true });
    const requestOptions = {
      credentials: 'same-origin',
      headers: this.headers(),
      method: 'DELETE',
    };
    return fetch(url + query, requestOptions).then((response) => this.handleResponse(response));
  }

  static handleResponse(response) {
    return response.text().then((text) => {
      const data = (text && JSON.parse(text)) || {};

      if (!response.ok) {
        if (!data.error) {
          data.error = {
            message: response.statusText,
          };
        }
        data.error.status = response.status;
        return Promise.reject(data.error);
      }

      return data;
    });
  }
}
