import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
function ArticleCard(props) {

  const { article } = props;
  const { id, name, description, slug } = article
  const ellipsisText = (text) => description.includes("img") ? text : text.substring(0, 70) + '...';

  return (
    <div className="latest-article-card-wrap">
      <Card>
        <Card.Body>
          <Card.Title className="h4">{name}</Card.Title>
            <Card.Text dangerouslySetInnerHTML={{__html: ellipsisText(description)}}></Card.Text>
          <Button variant="link" href={`/learning-centre/view/${id}/${slug}`}>Learn More</Button>
        </Card.Body>
      </Card>
    </div>
  )
}

export default ArticleCard;
