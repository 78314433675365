import React from "react";
import styled, { css } from 'styled-components';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import {Visible} from "react-grid-system";

function MainBanner() {
  const dataRoot = window.data;

  return (
    <Container className="h-100">
      <Row className="header-banner align-items-center h-100">
        <Col lg={12} className="col-xxl-10 offset-xxl-1 d-flex">
          <Row>
            <Col lg={6}>
              <Jumbotron className="text-white">
                <h1>{dataRoot.bannerHeader}</h1>

                {dataRoot.paths.mainPage && <p className="slogan">{dataRoot.environment.APP_MOTTO}</p>}
                <p className="slogan-description">{dataRoot.bannerContent}</p>
                <Visible xs sm md>
                  <Image src={dataRoot.bannerImage}
                         className="mb-5 mt-3 w-100"
                  />
                </Visible>
                <Button className="button-yellow-big on-intercom-run" href={dataRoot.links.bookDemo} variant="link">Book a demo</Button>
                {/*<Button className="button-link-blue-transparent" href="#" variant="link">Learn more</Button>*/}
              </Jumbotron>
            </Col>
            <Col md={6} className="text-center d-none d-md-flex d-lg-flex d-xl-flex d-xxl-flex header-banner-women-wrap align-self-center">
              <div className="header-banner-women">
                <Visible lg xl xxl>
                  <Image src={dataRoot.bannerImage} />
                </Visible>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default MainBanner;

