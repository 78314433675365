import React from 'react';
import ReactDOM from 'react-dom';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import PricingAccordion from '../components/PricingAccordion/PricingAccordion';
import FooterBanner from '../components/FooterBanner/FooterBanner';
import PricingColumns from '../components/PricingColumns/PricingColumns';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ParallaxConfetti from "../components/ParallaxEffects/Confetti";
import {ParallaxProvider} from "react-scroll-parallax";
import MainBanner from "../components/MainBanner/MainBanner";

export default function PricingPage() {
  const dataRoot = window.data;

  return (
    <ParallaxProvider>
      <div className="bg-white">
        <Header></Header>
        <div className="header-common"><MainBanner></MainBanner></div>
        <div className="position-relative">
          <div className="parallax-wrap position-absolute">
            <ParallaxConfetti></ParallaxConfetti>
          </div>
          <Container className="navbar-main-container pricing-page d-block">
            <Row>
              <Col lg={12} className="col-xxl-10 offset-xxl-1">
                {dataRoot.pricingPage.company.hasOrganisationSubscription && (
                  <>
                    <h2 className="latest-article-heading mb-3">
                      <img src={`${dataRoot.environment.APP_URL}/media/logos/loe-logo3.svg`} height="45" alt={dataRoot.environment.APP_NAME} className="svg mr-3" />
                      {dataRoot.pricingPage.company.logo && (
                        <img src={dataRoot.pricingPage.company.logo.replace(/&amp;/g, '&')} height="45" alt={dataRoot.pricingPage.company.name}/>
                      )}
                    </h2>
                  </>
                )}
                <PricingColumns></PricingColumns>
                <PricingAccordion></PricingAccordion>
              </Col>
            </Row>
          </Container>
        </div>
          <FooterBanner></FooterBanner>
          <Footer></Footer>
      </div>
    </ParallaxProvider>
  );
}

if (document.getElementById('page-pricing')) {
  ReactDOM.render(<PricingPage />, document.getElementById('page-pricing'));
}
