import React, { useEffect, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ArticleCard from '../ArticleCard/ArticleCard';
import Api from "../../Api";
import Spinner from 'react-bootstrap/Spinner'
import ParallaxConfetti from "../ParallaxEffects/Confetti";

function ArticleSection() {
  const dataRoot = window.data;

  // State
  const [articles, setArticles] = useState([]);
  const [isLoading, setLoading] = useState(true);
  // Effects
  useEffect(() => {
      api();
  }, []);

  async function api()
  {
    let response;
    try {
      response = await Api.articlesLatest();
      setLoading(false);
    } catch (err) {
      // TODO;
      return null;
    }

    setArticles(response.data);
  }

  const ArticleCards = articles.map((article) =>
    <Col lg={4} xs={6} className="mb-4" key={article.id}>
      <ArticleCard article={article}></ArticleCard>
    </Col>
  );

  return (
    <div className="position-relative">
      <div className="parallax-wrap position-absolute">
        <ParallaxConfetti part></ParallaxConfetti>
      </div>
      <Container className="py-5">
        <Row className="py-5">
          <Col lg={12} className="col-xxl-10 offset-xxl-1" >
            <h2 className="latest-article-heading">{dataRoot.articlesHeader} <Badge variant="primary">200</Badge></h2>
            <a className="latest-article-link" href={dataRoot.pathToArticles}>{dataRoot.articlesLinkName}</a>
            {isLoading ?
              <div className="align-items-center justify-content-center d-flex w-100 min-vh-100">
                <Spinner animation="border" className="article-spinner"/>
              </div>
              :
              <Row className="latest-article-card">{ArticleCards}</Row>
            }
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ArticleSection;
